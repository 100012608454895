import styled from "styled-components";

export const FooterCardGridWrapper = styled.div`
  margin-bottom: var(--sectionSpacing);

  @media screen and (min-width: 1128px) {
    margin-bottom: var(--sectionSpacingDesktop);
  }
`;

export const MiniCardsWrapper = styled.ul`
  display: grid;
  gap: var(--spacingS);
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(--spacingS);

  > a {
    text-decoration: none;
  }

  @media screen and (min-width: 1128px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const CardsWrapper = styled.ul`
  display: grid;
  gap: var(--spacingS);
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 1128px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
