import dynamic from "next/dynamic";
import {
  FooterCardGridWrapper,
  MiniCardsWrapper,
  CardsWrapper,
} from "./styles";

const MiniCardWithLink = dynamic(() =>
  import("../mini-card-with-link/mini-card-with-link")
);
const CardWithCta = dynamic(() => import("../card-with-cta/card-with-cta"));

export const FooterCardGrid = ({ miniCards, cards }) => {
  return (
    <FooterCardGridWrapper>
      {miniCards && (
        <MiniCardsWrapper>
          {miniCards.map((miniCard) => (
            <MiniCardWithLink {...miniCard} key={miniCard.id} />
          ))}
        </MiniCardsWrapper>
      )}
      {cards && (
        <CardsWrapper>
          <CardWithCta {...cards[0]} />
          {cards.map(
            (card, index) =>
              index > 0 && (
                <CardWithCta {...card} key={card.id} type="buttonCta" />
              )
          )}
        </CardsWrapper>
      )}
    </FooterCardGridWrapper>
  );
};

export default FooterCardGrid;
